<template>
  <div :class="{ 'push-down': isPartnerAccount}">
    <router-view />
    <el-container class="flex-wrap">
      <div class="header"
        :class="{ 'sticky-top': !layout.integratedHeader }">
        <div id="intro-hook" />
        <div class="p-20px flex-row-centered flex-align-start flex-wrap justify-content-space-between">
          <!-- Header buttons for creating port-like objects -->
          <div id="intro-step-1"
            class="flex-row-centered min-width-450px mt-10px">
            <el-tooltip v-if="!disabledFeatures.addPort"
              placement="bottom"
              :open-delay="1500">
              <template #content>
                <div class="big-tooltip">
                  <h3>{{ $t('productNames.port') }}</h3>
                  <p>{{ $t('ports.port-main-tooltip') }}</p>
                </div>
              </template>
              <router-link to="/create-megaport/port"
                class="d-block mr-10px">
                <el-button type="primary"
                  class="big-button"
                  data-name="create-port"
                  data-testid="create-port-button"
                  @click="trackButtonClick('create-port')">
                  <div class="flex-row-centered">
                    <mu-mega-icon icon="PORT"
                      class="icon" />
                    <div class="ml-0-5em">
                      {{ $t('general.create-thing', { thing: $t('productNames.port') }) }}
                    </div>
                  </div>
                </el-button>
              </router-link>
            </el-tooltip>

            <el-tooltip placement="bottom"
              :open-delay="1500">
              <template #content>
                <div class="big-tooltip">
                  <h3>{{ $t('productNames.mcr') }}</h3>
                  <p>{{ $t('ports.mcr-explanation') }}</p>
                </div>
              </template>
              <router-link to="/create-megaport/mcr"
                class="d-block mr-10px">
                <el-button type="primary"
                  class="big-button"
                  data-name="create-mcr"
                  data-testid="create-mcr-button"
                  @click="trackButtonClick('create-mcr')">
                  <div class="flex-row-centered">
                    <mu-mega-icon icon="MCR"
                      class="icon" />
                    <div class="ml-0-5em">
                      {{ $t('general.create-thing', { thing: $t('productNames.mcr') }) }}
                    </div>
                  </div>
                </el-button>
              </router-link>
            </el-tooltip>

            <el-tooltip placement="bottom"
              :open-delay="1500">
              <template #content>
                <div class="big-tooltip">
                  <h3>{{ $t('productNames.mve') }}</h3>
                  <p>{{ $t('ports.mve-explanation') }}</p>
                </div>
              </template>
              <router-link to="/mve"
                class="d-block mr-10px">
                <el-button type="primary"
                  class="big-button"
                  data-name="create-mve"
                  data-testid="create-mve-button"
                  @click="trackButtonClick('mve')">
                  <div class="flex-row-centered">
                    <mu-mega-icon icon="MVE"
                      class="icon" />
                    <div class="ml-0-5em">
                      {{ $t('general.create-thing', { thing: $t('productNames.mve') }) }}
                    </div>
                  </div>
                </el-button>
              </router-link>
            </el-tooltip>
          </div>
          <!-- Filtering, Sorting, Collapsing, and Inventory Download -->
          <div class="d-flex gap-1 mt-10px">
            <!-- Filter Options -->
            <service-filters />

            <!-- Sort Options -->
            <div>
              <!-- Sort By -->
              <el-select :value="sortBy"
                class="sortService"
                @change="sortByChanged">
                <el-option :label="$t('services.creation-date')"
                  value="creation" />
                <el-option :label="$t('general.name')"
                  value="name" />
                <el-option :label="$t('general.location')"
                  value="location" />
              </el-select>
              <!-- Sorting Direction -->
              <el-tooltip placement="bottom"
                :content="$t('services.change-sort-direction')"
                :open-delay="500">
                <el-button class="height-fit"
                  @click="toggleSortAscending">
                  <i class="fas"
                    :class="sortAscending ? 'fa-sort-amount-up' : 'fa-sort-amount-down'"
                    aria-hidden="true" />
                </el-button>
              </el-tooltip>
            </div>

            <!-- Extra Actions -->
            <el-dropdown trigger="click"
              class="height-fit"
              @command="handleActionCommand">
              <el-tooltip placement="top"
                :content="$t('services.extra-actions')"
                :open-delay="500">
                <el-button type="secondary">
                  <i class="fas fa-ellipsis-vertical" />
                </el-button>
              </el-tooltip>

              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="action in extraActions"
                  :key="action.command"
                  :command="action.command">
                  <template #default>
                    <i v-if="action.icon"
                      :class="action.icon" />
                    <mu-mega-icon v-if="action.megaIcon"
                      width="14"
                      class="pr-4px"
                      :icon="action.megaIcon" />
                    {{ action.label }}
                  </template>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>

      <!-- Service List -->
      <el-main class="p-20px pb-50px max-width-900px m-auto">
        <div v-if="!servicesReady"
          v-loading="true"
          :element-loading-text="$t('general.loading')"
          :element-loading-fullscreen="false"
          element-loading-background="rgba(0, 0, 0, 0.0)"
          class="mt-2 height-40px z-0" />
        <no-services v-else-if="primaryPorts.length < 1" />
        <template v-else>
          <!-- Use v-show as a lot of updates will come in a short time, so we don't want to discard previous work -->
          <div v-show="filterApplied && filteredPorts.length < 1">
            <h3 class="m-auto width-fit-content">
              {{ $t('services.no-matching') }}
            </h3>
          </div>
          <port-line v-for="port in sortedPorts"
            v-show="!filterApplied || filteredPorts.includes(port.productUid)"
            :key="port.productUid"
            :port="port"
            :data-service-name="port.productName"
            :data-port-location="port._location ? port._location.metro : null"
            @showOnMap="showOnMap" />
        </template>
      </el-main>

      <!-- MVE Megaport Internet Reminder -->
      <transit-modal />
    </el-container>
  </div>
</template>

<script>
// External tools
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { isEmpty } from 'lodash'
import sdk from '@megaport/api-sdk'
// Internal tools
import { setServicesPage, setFocussedUid } from '@/utils/MapDataUtils'
import { downloadFile } from '@/utils/downloadFile'
import { captureEvent } from '@/utils/analyticUtils'
// Components
import PortLineComponent from '@/components/services/Ports.vue'
import NoServicesComponent from '@/components/services/NoServices.vue'
import ServiceFiltersComponent from '@/components/services/Filters.vue'
import TransitModalComponent from '@/components/ui-components/TransitModal.vue'
// Globals
import { ACCOUNT_TYPE_PARTNER } from '@/Globals.js'

const SERVICES_SORT_KEY = 'servicesSort'
const SERVICES_SORT_ASCENDING_KEY = 'servicesSortAscending'

const SORT_BY_CREATION = 'creation'
const SORT_BY_LOCATION = 'location'
const SORT_BY_NAME = 'name'

const DROPDOWN_ACTION_EXPAND_ALL = 'expand'
const DROPDOWN_ACTION_COLLAPSE_ALL = 'collapse'
const DROPDOWN_ACTION_SERVICE_STATUS = 'ssp'
const DROPDOWN_ACTION_DOWNLOAD_INVENTORY_REPORT = 'report'

export default {
  name: 'ServicesPage',

  components: {
    'port-line': PortLineComponent,
    'no-services': NoServicesComponent,
    'transit-modal': TransitModalComponent,
    'service-filters': ServiceFiltersComponent,
  },

  inject: ['layout', 'disabledFeatures'],

  data() {
    return {
      sortBy: localStorage.getItem(SERVICES_SORT_KEY) || SORT_BY_CREATION,
      sortAscending: JSON.parse(localStorage.getItem(SERVICES_SORT_ASCENDING_KEY)) ?? false,
      ACCOUNT_TYPE_PARTNER,
    }
  },

  computed: {
    ...mapState('ApplicationContext', ['data']),
    ...mapState('Services', ['collapsedServiceUids', 'servicesReady']),
    ...mapGetters('Auth', ['isPartnerAccount']),
    ...mapGetters('ApplicationContext', ['companyContextLoading']),
    ...mapGetters('Company', ['companyUid']),
    ...mapGetters('ServiceFilters', ['filterApplied', 'matchingServices', 'primaryPorts', 'urlQueryFilters']),
    ...mapGetters('Services', ['myPorts', 'portUidDictionary']),

    sortedPorts() {
      const ports = [...this.primaryPorts]

      switch (this.sortBy) {
        case SORT_BY_CREATION:
          ports.sort((a, b) => (this.sortAscending ? a.createDate - b.createDate : b.createDate - a.createDate))
          break

        case SORT_BY_LOCATION:
          ports.sort((a, b) => {
            const aLocation = `${a._location?.country} ${a._location?.name}`
            const bLocation = `${b._location?.country} ${b._location?.name}`

            if (this.sortAscending) {
              return aLocation.localeCompare(bLocation)
            }
            return bLocation.localeCompare(aLocation)
          })
          break

        case SORT_BY_NAME:
          ports.sort((a, b) => {
            if (this.sortAscending) {
              return a.productName.localeCompare(b.productName)
            }
            return b.productName.localeCompare(a.productName)
          })
          break
      }

      return ports
    },
    filteredPorts() {
      if (!this.filterApplied) return []

      // Search for ports that match or have any services or sublags that match the filter
      return this.primaryPorts.reduce((list, port) => {
        if (this.matchingServices.includes(port.productUid)) return [...list, port.productUid]

        for (const vxc of port.associatedVxcs) {
          if (this.matchingServices.includes(vxc.productUid)) return [...list, port.productUid]
        }
        for (const ix of port.associatedIxs) {
          if (this.matchingServices.includes(ix.productUid)) return [...list, port.productUid]
        }
        if (port._subLags) for (const lag of port._subLags) {
          if (this.matchingServices.includes(lag.productUid)) return [...list, port.productUid]
        }
        return list
      }, [])
    },
    allServicesCollapsed() {
      const collapsableServiceUids = this.myPorts
        .filter(port => {
          const isLag = port.aggregationId || port.lagPortCount

          if (!isLag || isLag && port.lagPrimary) {
            return port.associatedVxcs.length > 0 || port.associatedIxs.length > 0
          }

          return false
        })
        .map(port => port.productUid)

      if (isEmpty(collapsableServiceUids) || isEmpty(this.collapsedServiceUids)) return false

      return collapsableServiceUids.every(uid => this.collapsedServiceUids.includes(uid))
    },
    extraActions() {
      return [
        {
          label: this.allServicesCollapsed ? this.$t('services.expand-all') : this.$t('services.collapse-all'),
          icon: this.allServicesCollapsed ? 'fas fa-arrows-from-line' : 'fas fa-arrows-to-line',
          command: this.allServicesCollapsed ? DROPDOWN_ACTION_EXPAND_ALL : DROPDOWN_ACTION_COLLAPSE_ALL,
        },
        {
          label: this.$t('service-status.maintenance-outages'),
          megaIcon: 'outages',
          command: DROPDOWN_ACTION_SERVICE_STATUS,
        },
        {
          label: this.$t('services.download-inventory-report'),
          icon: 'fas fa-file-csv',
          command: DROPDOWN_ACTION_DOWNLOAD_INVENTORY_REPORT,
        },
      ]
    },
  },

  watch: {
    companyContextLoading(newValue) {
      if (newValue === false) {
        setServicesPage('/services')
      }
    },
    urlQueryFilters(query) {
      this.$router.replace({ query }).catch(() => {})
    },
  },

  mounted() {
    const storedCollapsedStates = JSON.parse(localStorage.getItem('portCollapsedStates')) ?? {}

    Object.keys(storedCollapsedStates)
      .forEach(uid => {
        if (storedCollapsedStates[uid] === true) {
          this.addCollapsedServiceUid(uid)
        }
      })
  },

  activated() {
    setServicesPage('/services')
    this.applyUrlQueryFilters(this.$route.query)
  },

  deactivated() {
    this.closeTooltips(this)
  },

  methods: {
    ...mapActions('Services', ['addCollapsedServiceUid']),
    ...mapMutations('ServiceFilters', ['applyUrlQueryFilters']),

    showOnMap(productUid) {
      setFocussedUid(productUid)
      this.$router.push('/dashboard')
    },
    sortByChanged(value) {
      this.sortBy = value
      localStorage.setItem(SERVICES_SORT_KEY, value)
    },
    toggleSortAscending() {
      this.sortAscending = !this.sortAscending
      localStorage.setItem(SERVICES_SORT_ASCENDING_KEY, JSON.stringify(this.sortAscending))
    },
    /**
     * Because this component is wrapped in a keep-alive, we need to hook into the deactivated
     * lifecycle method and close any open tooltips before going on to the next screen.
     */
    closeTooltips(thisComponent) {
      if (thisComponent.$options.name === 'ElTooltip') {
        thisComponent.hide()
      }
      for (const component of thisComponent.$children) {
        this.closeTooltips(component)
      }
    },
    handleExpandCollapseAll(command) {
      const expanded = command === DROPDOWN_ACTION_EXPAND_ALL
      const eventToEmit = expanded ? 'expandAll' : 'collapseAll'

      this.$root.$emit(eventToEmit)
    },
    /**
     * Downloads the service inventory report for the current company.
     */
    async downloadInventoryReport() {
      const url = `${sdk.instance.baseurl}/v2/secure/inventory/companies/${this.companyUid}/services/csv`

      try {
        await downloadFile(url)
      } catch {
        /* handled by downloadFile */
      }
    },
    /**
     * Handles the extra actions dropdown commands.
     * @param {string} command The command that was selected.
     */
    handleActionCommand(command) {
      switch (command) {
        case DROPDOWN_ACTION_EXPAND_ALL:
        case DROPDOWN_ACTION_COLLAPSE_ALL:
          this.handleExpandCollapseAll(command)
          break
        case DROPDOWN_ACTION_SERVICE_STATUS:
          this.$router.push('/service-status')
          break
        case DROPDOWN_ACTION_DOWNLOAD_INVENTORY_REPORT:
          this.downloadInventoryReport()
          break
      }
    },
    trackButtonClick(buttonName) {
      captureEvent(`services.${buttonName}.click`)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~element-ui/packages/theme-chalk/src/mixins/mixins.scss";
@import "~element-ui/packages/theme-chalk/src/mixins/_button.scss";

.push-down {
  margin-top: -60px;

  .header {
    padding-top: 60px;
  }
}
.header {
  width: 100%;
  padding: 0;
  background-color: var(--mp-sub-header-background-color);
  border-bottom: 1px solid var(--card-border-color);
}

.width-fit-content {
  width: fit-content;
}

.min-width-450px {
  min-width: 450px;
}

.max-width-900px {
  max-width: 900px;
}

svg.icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  fill: currentColor;
  line-height: 0;
}
$--button-big-font-size: 1.6rem;
$--button-big-border-radius: var(--border-radius-base);
$--button-big-padding-vertical: 10px;
$--button-big-padding-horizontal: 20px;

.big-button {
  @include button-size(
    $--button-padding-vertical,
    $--button-padding-horizontal,
    $--button-font-size,
    $--button-border-radius
  );
  @include res(md) {
    @include button-size(
      $--button-big-padding-vertical,
      $--button-big-padding-horizontal,
      $--button-big-font-size,
      $--button-big-border-radius
    );
  }
}

.big-tooltip {
  text-align: left;
  font-size: 1.6rem;
  p {
    color: #ffffff;
  }
  h3 {
    color: #ffffff;
    font-weight: bold;
  }
}

.sortService {
  width: 160px;
}

.intro-hook {
  height: 100%;
  width: 0;
  float: left;
}

.ml-10px {
  margin-left: 10px;
}

.ml-0-5em {
  margin-left: 0.5em;
}

.mr-10px {
  margin-right: 10px;
}

.mt-10px {
  margin-top: 10px;
}

.pr-4px {
  padding-right: 4px;
}

.z-0 {
  z-index: 0;
}
</style>
