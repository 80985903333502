<template>
  <div class="flex-column">
    <div class="flex-row-centered justify-content-end">
      <!-- Text Filter -->
      <el-input :value="localTextFilter"
        data-testid="service-filter-input"
        name="searchFilter"
        :placeholder="$t('services.filter')"
        data-name="filter-services"
        suffix-icon="fal fa-filter"
        class="filter-input"
        clearable
        @input="setLocalTextFilter" />

      <!-- Additional Filters -->
      <lazy-popover v-model="visible"
        placement="bottom-start"
        :width="250"
        trigger="click">
        <!-- Product Types -->
        <h5 class="mb-1-5">
          {{ $t('services.service-type') }}
        </h5>
        <el-checkbox-group :value="productFilters"
          class="flex-row-centered flex-align-start"
          @input="setProductFilters">
          <div class="flex-column">
            <el-checkbox :label="G_PRODUCT_TYPE_MEGAPORT">
              {{ $t('productNames.port') }}
            </el-checkbox>
            <el-checkbox :label="G_PRODUCT_TYPE_MCR2">
              {{ $t('productNames.mcr') }}
            </el-checkbox>
            <el-checkbox :label="G_PRODUCT_TYPE_MVE">
              {{ $t('productNames.mve') }}
            </el-checkbox>
          </div>
          <div class="flex-column">
            <el-checkbox :label="G_PRODUCT_TYPE_VXC">
              {{ $t('productNames.vxc') }}
            </el-checkbox>
            <el-checkbox :label="G_PRODUCT_TYPE_IX">
              {{ $t('productNames.ix') }}
            </el-checkbox>
          </div>
        </el-checkbox-group>

        <!-- Terms -->
        <h5 class="mt-2-5 mb-1-5">
          {{ $t(isPartnerVantage ? 'partner-vantage.subscription-term' : 'services.contract-term') }}
        </h5>
        <el-checkbox-group :value="termFilters"
          class="flex-column"
          @input="setTermFilters">
          <el-checkbox :label="1">
            {{ $tc('general.count-months', 1) }} / {{ $t('port-term.no-minimum-term') }}
          </el-checkbox>
          <el-checkbox :label="12">
            {{ $tc('general.count-months', 12) }}
          </el-checkbox>
          <el-checkbox :label="24">
            {{ $tc('general.count-months', 24) }}
          </el-checkbox>
          <el-checkbox :label="36">
            {{ $tc('general.count-months', 36) }}
          </el-checkbox>
        </el-checkbox-group>

        <!-- Footer -->
        <div class="mt-2">
          <el-button size="small"
            type="text"
            @click="resetFilters">
            {{ $t('services.clear-filters') }}
          </el-button>
          <el-button class="float-right"
            size="small"
            @click="visible = false">
            {{ $t('general.close') }}
          </el-button>
        </div>

        <!-- Additional Filters Button -->
        <template #reference>
          <el-tooltip placement="bottom"
            :content="$t('services.view-additional-filters')"
            :open-delay="500"
            :disabled="visible">
            <el-button>
              <i class="fas fa-sliders-simple"
                aria-hidden="true" />
            </el-button>
          </el-tooltip>
        </template>
      </lazy-popover>
    </div>

    <!-- Clear filter text -->
    <div class="clear-filter-message">
      <!-- Toggle the children instead of the parent div so we can reserve space in the layout -->
      <template v-if="filterApplied">
        {{ $tc('services.matching-services', matchingServices.length, { total: uniqueServiceCount }) }}
        <el-button size="small"
          type="text"
          @click="resetFilters">
          {{ $t('services.clear-filters') }}
        </el-button>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { debounce } from 'lodash'

import LazyPopoverComponent from '@/components/ui-components/LazyPopover.vue'

export default {
  name: 'ServiceFilters',

  components: {
    'lazy-popover': LazyPopoverComponent,
  },

  data() {
    return {
      visible: false,
      localTextFilter: '',
    }
  },

  computed: {
    ...mapState('ServiceFilters', ['productFilters', 'termFilters', 'textFilter']),
    ...mapGetters('ServiceFilters', ['filterApplied', 'matchingServices', 'uniqueServiceCount']),
    ...mapGetters('Auth', ['isPartnerVantage']),
  },

  created() {
    this.debouncedSetTextFilter = debounce(this.setTextFilter, 500)
    this.localTextFilter = this.$route.query.search
  },

  methods: {
    ...mapMutations('ServiceFilters', ['clearFilters', 'setProductFilters', 'setTermFilters', 'setTextFilter']),
    setLocalTextFilter(value) {
      this.localTextFilter = value
      this.debouncedSetTextFilter(value)
    },
    resetFilters() {
      this.clearFilters()
      this.localTextFilter = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-input {
  width: 194px;
}

.clear-filter-message {
  font-size: 1.2rem;
  height: 2rem;
  color: var(--color-text-primary);

  button {
    margin-left: 1rem;
  }
}
</style>
