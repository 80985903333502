<template>
  <div>
    <h4 class="add-conn-header">
      {{ $t('connections.add-connection') }}
    </h4>

    <div class="flex-container pt-1">
      <router-link v-for="item in displayItems"
        :key="item.companyUid"
        :to="`/create-connection/${port.productUid}?${getArgs(item)}`"
        :data-name="item.title"
        class="text-decoration-none">
        <el-button class="el-card card-format is-hover-shadow"
          @click="trackClick(item.connectType)">
          <mu-mega-icon v-if="item.megaIcon"
            :icon="item.megaIcon"
            class="svg-icon" />
          <img v-else
            :src="item.logo || companyLogo(item.companyUid)"
            :alt="$t('images.marketplace-logo')"
            @error="setFallbackImage($event, '/fallback-images/mp-placeholder.png')">
          <p class="text-decoration-none border-0">
            {{ item.title }}
          </p>
        </el-button>
      </router-link>
    </div>
    <div class="flex-row-centered flex-justify-center py-1">
      <router-link :to="`/create-connection/${port.productUid}?type=MX`">
        <el-button name="viewMoreProviders"
          :data-serviceid="port.productUid"
          :data-testid="`addConnection-${port._subUid}`"
          @click="trackClick('MX')">
          <div class="flex-row-centered flex-justify-center">
            <i class="fal fa-plus-circle action-icon"
              aria-hidden="true" />
            <span class="connection-text">
              {{ $t('connections.search-for-providers') }}
            </span>
          </div>
        </el-button>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { CLOUD_ITEMS, RACKSPACE_DETAILS } from '@/Globals.js'
import { setFallbackImage } from '@/utils/fallbackImage'
import { captureEvent, productTypeToEvent } from '@/utils/analyticUtils'

export default {
  name: 'QuickConnect',

  inject: ['disabledFeatures'],

  props: {
    port: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState('Marketplace', ['marketplaceData']),
    ...mapState('Services', ['transitEnabledMarkets']),
    ...mapGetters('Auth', ['hasFeatureFlag']),

    transitEnabledMarket() {
      // All markets that offer MVEs are transit-enabled
      if (this.port.productType === this.G_PRODUCT_TYPE_MVE) return true

      // For Ports and MCRs, transit is enabled for specific markets, which is fetched from the API.
      return this.transitEnabledMarkets
        .filter(({ aConnectType }) => aConnectType === this.port.connectType)
        .some(({ countryCode }) => countryCode === this.port?._location?.market)
    },

    displayItems() {
      return this.defaultItems.filter(item => {
        // Make sure that we don't show the IX option for MCR and MVE, or the Megaport Internet option for non-MVE
        if (item.connectType === 'IX' && [this.G_PRODUCT_TYPE_MVE, this.G_PRODUCT_TYPE_MCR2].includes(this.port.productType))
          return false

        // Display the Megaport Internet VXC tile only for enabled markets
        if (item.connectType === 'TRANSIT' && !this.transitEnabledMarket) return false

        // Hide any cloud providers that are set to be hidden
        if (item.hideQuickConnect) return false

        return true
      })
    },
    defaultItems() {
      return (
        CLOUD_ITEMS.map(item => ({ ...item, companyUid: item.companyUids[0] }))
          // if port in design, then allow all connections
          // if vxcPermitted = true, then allow all connections, VXC & IX
          // if vxcPermitted = false, then allow IX, do not allow VXC
          .filter(item => this.port.vxcPermitted !== false || item.connectType === 'IX')
      )
    },
  },

  methods: {
    setFallbackImage,
    companyLogo(uid) {
      return `${this.$appConfiguration.marketplaceMediaUrl}/${uid}`
    },
    getArgs(item) {
      if (item.connectType === 'DEFAULT') {
        return `type=DEFAULT&companyUid=${item.companyUid}`
      }
      if (item.connectType === 'IX') {
        return 'type=IX'
      }
      if (item.connectType === 'TRANSIT') {
        return 'type=TRANSIT'
      }
      if (item.connectType === RACKSPACE_DETAILS.connectType) {
        return `type=mx&companyUid=${item.companyUid}`
      }
      return `cloud=${item.companyUid}`
    },
    trackClick(connectTypes) {
      const connectType = Array.isArray(connectTypes) ? connectTypes[0] : connectTypes
      captureEvent(`services.${productTypeToEvent(this.port.productType)}.quick-connect.${connectType.toLowerCase()}.click`)
    },
  },
}
</script>

<style lang="scss" scoped>
.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.search-icon {
  margin-left: 4px;
}
.card-format {
  margin: 0.1rem;
  border-radius: 5px;
  text-align: center;
  width: 126px;
  border: none;

  // We need the following to undo stylings supplied by el-button so we can pretend we're a card
  font: inherit;
  transition: 0.3s;
  padding: 0;
  &:hover {
    background-color: var(--color-white);
  }

  img {
    width: 60px;
    height: 60px;
  }
  .svg-icon {
    color: var(--color-primary);
    font-size: 4em;
    margin: 5px auto 5px auto;
    width: 50px;
    height: 50px;
  }
  p {
    width: 100%;
    font-size: 1.2rem;
    margin: 0;
    padding: 0px 2px 2px 0px;
  }
}
.add-conn-header {
  margin: 1.5rem 0 0 0;
  text-align: center;
  color: var(--color-text-regular);
}
.add-conn-search-filter {
  max-width: 500px;
  margin: 2rem auto 1rem auto;
}
</style>

<style lang="scss">
.card-format {
  .el-card__body {
    padding: 0;
  }
}
</style>
