<template>
  <div class="configured-extras flex-row-centered service-box position-relative"
    :class="{ filterMatch: matchingServices.includes(ix.productUid) }"
    :data-service-name="ix.productName"
    :data-service-type="ix.productType"
    :data-provisioning-status="ix.provisioningStatus">
    <cancel-service v-if="showCancelPanel"
      :visible.sync="showCancelPanel"
      :service-uid="ix.productUid" />

    <div class="connection-arrow--vertical" />
    <div class="connection-arrow--horizontal" />

    <lazy-popover width="fit-content"
      placement="top"
      :open-delay="500"
      trigger="hover">
      <div class="text-align-left fs-1-4rem p-1">
        <p class="text-align-center my-0">
          <strong>{{ statusInfo.message }}</strong>
        </p>
        <template v-if="statusInfo.bgpStatuses.length">
          <p class="mt-2 mb-1">
            <strong>{{ $t('connections.bgp-statuses') }}</strong>
          </p>
          <div v-for="(status, index) in statusInfo.bgpStatuses"
            :key="index"
            class="flex-row-centered position-relative">
            <div class="service-status-icon"
              :class="status.status" />
            <p class="ml-1 my-0-5">
              {{ status.message }}
            </p>
          </div>
        </template>
      </div>

      <template #reference>
        <mu-mega-icon icon="IX"
          class="service-icon service-status-indicator service-page-service-icon"
          :class="[whiteLabelClass, calcStatus]"
          :data-service-name="ix.productName"
          :data-status="ix.provisioningStatus"
          data-testid="service-icon"
          @click="showDetails()" />
      </template>
    </lazy-popover>

    <div class="cursor-pointer service-info-text"
      @click="showDetails()">
      <div class="service-header">
        {{ ix.productName }}
        <el-tooltip v-if="!serviceIsInDesign"
          placement="right"
          :content="$t('services.identifier-copy')"
          :open-delay="500">
          <span class="service-identifier"
            @click.stop="copyToClipboard(serviceUid)">#{{ serviceUid }}</span>
        </el-tooltip>
        <span v-if="!serviceIsLive && serviceIsShutdown"
          class="ml-0-5">({{ snakeToCapitalizedWords(ix.provisioningStatus) }}, {{ $t('general.shut-down') }})</span>
        <span v-else-if="!serviceIsLive || serviceIsShutdown"
          class="ml-0-5">({{ !serviceIsLive ? snakeToCapitalizedWords(ix.provisioningStatus) : $t('general.shut-down') }})</span>
      </div>
      <div class="service-details"
        :data-speed="displaySpeed">
        {{ $t('productNames.ix') }} ({{ displaySpeed }}) - {{ displayName }}
      </div>
      <conflict :service="ix" />
    </div>
    <div class="action-block tool-buttons ix-block"
      role="menubar">
      <el-tooltip placement="top"
        :content="$t('menu.ix-telemetry')"
        :open-delay="500">
        <router-link v-if="!serviceIsInDesign"
          :to="`/tools/ix-flows/${ix.productUid}`">
          <el-button data-name="IXGraphs" 
            @click="trackButtonClick('telemetry')">
            <i class="fas fa-chart-area action"
              aria-hidden="true" />
          </el-button>
        </router-link>
      </el-tooltip>

      <el-tooltip placement="top"
        :content="moveTooltip"
        :open-delay="500"
        popper-class="move-connection-tooltip">
        <div>
          <!-- Don't use router-link here otherwise we can't properly disable navigation -->
          <el-button :disabled="noMoveReason != null"
            name="moveConnection"
            data-testid="move-connection"
            @click="moveIx">
            <i class="fal fa-exchange action"
              aria-hidden="true" />
          </el-button>
        </div>
      </el-tooltip>

      <el-tooltip placement="top"
        :open-delay="500">
        <template #content>
          <div class="text-align-center">
            <div>{{ $t('general.type-details', { type: $t('productNames.ix')}) }}</div>
            <div v-if="serviceIsShutdown">
              {{ $t('general.type-shutdown-state', { type: $t('productNames.ix')}) }}
            </div>
          </div>
        </template>
        <div>
          <el-button name="showDetails"
            :class="{ 'is-shutdown': serviceIsShutdown }"
            data-testid="edit-ix"
            @click="showDetails()">
            <i :class="serviceIsShutdown ? 'fa' : 'fal'"
              class="fa-cog action"
              aria-hidden="true" />
          </el-button>
        </div>
      </el-tooltip>

      <el-tooltip v-if="!disabledFeatures.dashboard"
        placement="top"
        :content="$t('map.view-in-map')"
        :open-delay="500">
        <div>
          <el-button name="show-on-map"
            label="map"
            @click="showOnMap">
            <i class="fal fa-location-dot action" />
          </el-button>
        </div>
      </el-tooltip>

      <el-tooltip placement="top"
        :content="deleteTooltip"
        :open-delay="500">
        <div>
          <el-button data-name="deleteService"
            :disabled="!canDeleteIX"
            data-testid="delete-ix"
            @click="toggleCancelService()">
            <i class="fal fa-trash-alt action"
              aria-hidden="true" />
          </el-button>
        </div>
      </el-tooltip>

      <el-tooltip v-show="!ix.locked && !ix.adminLocked && showLock"
        placement="top"
        :content="lockTooltip"
        :open-delay="500">
        <div>
          <el-button v-loading="loading"
            :disabled="!isAdmin || !serviceIsLive"
            name="lock"
            data-testid="lock-ix"
            @click="lockIx">
            <i class="fal fa-unlock-alt action"
              aria-hidden="true" />
          </el-button>
        </div>
      </el-tooltip>
      <el-tooltip v-show="ix.locked || ix.adminLocked"
        placement="top"
        :content="lockTooltip"
        :open-delay="500">
        <div>
          <el-button v-loading="loading"
            :disabled="!isAdmin || !serviceIsLive || ix.adminLocked"
            name="unlock"
            data-testid="unlock-ix"
            @click="unlockIx">
            <i class="fal fa-lock action"
              :class="{adminLocked:ix.adminLocked}"
              aria-hidden="true" />
          </el-button>
        </div>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import sdk from '@megaport/api-sdk'

import captureSentryError from '@/utils/CaptureSentryError.js'
import { copyToClipboard, convertSpeed, snakeToCapitalizedWords } from '@/helpers.js'
import { connectionNoMoveReason, portlikeNoMoveReason } from '@/utils/moveConnections'
import { captureEvent, productTypeToEvent } from '@/utils/analyticUtils'

import ConflictComponent from '@/components/services/Conflict.vue'
import CancelServiceComponent from '@/components/cancel/Cancel.vue'
import LazyPopoverComponent from '@/components/ui-components/LazyPopover.vue'

export default {
  name: 'IxComponent',

  components: {
    conflict: ConflictComponent,
    'cancel-service': CancelServiceComponent,
    'lazy-popover': LazyPopoverComponent,
  },

  inject: ['disabledFeatures'],

  props: {
    ix: {
      type: Object,
      required: true,
    },
    port: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      statsOpen: false,
      loading: false,
      showCancelPanel: false,
    }
  },

  computed: {
    ...mapGetters('Auth', ['hasAuth']),
    ...mapGetters('Services', ['showLock']),
    ...mapGetters('ServiceFilters', ['matchingServices']),

    whiteLabelClass() {
      return this.serviceIsInDesign ? `wl-vxc-configured-color` : `wl-vxc-color`
    },
    statusInfo() {
      const statusInfo = {
        status: '',
        message: '',
        bgpStatuses: [],
      }

      if (!this.serviceIsLive) {
        statusInfo.message = `${this.$t('services.provisioning-status')}: ${this.ix.provisioningStatus}`
      } else if (typeof this.ix.up !== 'boolean') {
        statusInfo.status = 'partial-success'
        statusInfo.message = this.$t('connections.ix-status-unknown')
      } else {
        statusInfo.status = this.ix.up ? 'all-go' : 'all-fail'
        statusInfo.message = this.ix.up ? this.$t('connections.ix-is-up') : this.$t('connections.ix-is-down')

        // Add the BGP statuses as informational content.
        if (this.ix.resources?.bgp_connection) {
          for (const bgp of this.ix.resources.bgp_connection) {
            const thisStatus = {}

            thisStatus.message = `${this.$t('services.ip-address')}: ${bgp.isp_ip_address}`

            switch (bgp.up) {
              case 0:
                thisStatus.status = 'all-fail'
                break
              case 1:
                thisStatus.status = 'all-go'
                break
              default:
                thisStatus.status = 'partial-success'
                break
            }

            statusInfo.bgpStatuses.push(thisStatus)
          }
        }
      }

      return statusInfo
    },
    serviceUid() {
      return this.ix.productUid.split('-')[0]
    },
    displaySpeed() {
      return convertSpeed(this.ix.rateLimit)
    },
    displayName() {
      if (!this.$store.state.IXTypes.ixTypes[this.ix.locationId]) {
        this.$store.dispatch('IXTypes/getIxType', this.ix.locationId)
      } else {
        const foundInfo = this.$store.state.IXTypes.ixTypes[this.ix.locationId].find(loc => loc.name === this.ix.networkServiceType)
        if (foundInfo) {
          return foundInfo.description || foundInfo.name
        }
      }
      return this.ix.networkServiceType
    },
    isAdmin() {
      return this.hasAuth('company_admin')
    },
    canPlaceOrder() {
      return this.hasAuth('place_order')
    },
    canDeleteIX() {
      if (this.ix.adminLocked || this.ix.locked) return false
      if ([this.G_PROVISIONING_DESIGN_DEPLOY, this.G_PROVISIONING_FAILED, this.G_PROVISIONING_DECOMMISSIONED].includes(this.ix.provisioningStatus)) return false
      return this.serviceIsInDesign || this.canPlaceOrder
    },
    noMoveReason() {
      return portlikeNoMoveReason(this.port) || connectionNoMoveReason(this.ix)
    },
    moveTooltip() {
      if (this.noMoveReason) {
        return this.$t(this.noMoveReason, { type: this.$t('productNames.ix') })
      }
      return this.$t('connections.move-connection')
    },
    deleteTooltip() {
      if (this.ix.locked || this.ix.adminLocked) {
        return this.$t('services.type-locked', { type: this.$t('productNames.ix') })
      }
      if (this.ix.provisioningStatus === this.G_PROVISIONING_DESIGN_DEPLOY) {
        return this.$t('services.type-being-deployed', { type: this.$t('productNames.ix') })
      }
      // This can happen if a service is decommissioned afer the services have been loaded and has not yet
      // been cleaned up during periodic updates.
      if (this.ix.provisioningStatus === this.G_PROVISIONING_DECOMMISSIONED) {
        return this.$t('services.decommissioned')
      }
      if (this.canDeleteIX) {
        return this.$t('general.delete-type', { type: this.$t('productNames.ix') })
      }
      return this.$t('general.delete-permission-denied', { type: this.$t('productNames.ix') })
    },
    lockTooltip() {
      if (this.ix.adminLocked) {
        return this.$t('services.service-locked')
      }
      if (this.ix.locked) {
        if (this.isAdmin) {
          if (this.serviceIsLive) {
            return this.$t('services.type-unlock', { type: this.$t('productNames.ix') })
          } else {
            return this.$t('services.type-unlock-notlive', { type: this.$t('productNames.ix') })
          }
        } else {
          return this.$t('services.type-locked-admin', { type: this.$t('productNames.ix') })
        }
      } else if (this.isAdmin) {
        if (this.serviceIsLive) {
          return this.$t('services.type-lock', { type: this.$t('productNames.ix') })
        } else {
          return this.$t('services.type-lock-notlive', { type: this.$t('productNames.ix') })
        }
      } else {
        return this.$t('services.type-unlocked-admin', { type: this.$t('productNames.ix') })
      }
    },
    calcStatus() {
      return this.statusInfo.status
    },
    serviceIsInDesign() {
      return this.ix.provisioningStatus === this.G_PROVISIONING_DESIGN
    },
    serviceIsLive() {
      return this.ix.provisioningStatus === this.G_PROVISIONING_LIVE
    },
    serviceIsShutdown() {
      return this.ix.shutdown ?? this.ix.resources?.vpls_interface?.shutdown ?? false
    },
  },

  methods: {
    copyToClipboard,
    snakeToCapitalizedWords,

    toggleCancelService() {
      this.showCancelPanel = true
      this.trackButtonClick(this.serviceIsInDesign ? 'delete-config' : 'delete')
    },
    showDetails() {
      if (this.serviceIsInDesign) {
        this.$router.push(`/create-connection/${this.ix.productUid}`, () => {})
        this.trackButtonClick('edit-config')
      } else {
        this.$router.push(`/edit-connection/${this.ix.productUid}`, () => {})
        this.trackButtonClick('edit')
      }
    },
    showOnMap() {
      this.$emit('showOnMap', this.ix)
      this.trackButtonClick('view-map')
    },
    lockIx() {
      this.loading = true
      sdk.instance
        .product(this.ix.productUid)
        .lock()
        .then(() => {
          this.loading = false
          this.$store.commit('Services/setLockedState', { obj: this.ix, locked: true })
        })
        .catch(e => {
          this.loading = false
          captureSentryError(e)
        })
      this.trackButtonClick('lock')
    },
    unlockIx() {
      this.loading = true
      sdk.instance
        .product(this.ix.productUid)
        .unlock()
        .then(() => {
          this.loading = false
          this.$store.commit('Services/setLockedState', { obj: this.ix, locked: false })
        })
        .catch(e => {
          this.loading = false
          captureSentryError(e)
        })
      this.trackButtonClick('unlock')
    },
    moveIx() {
      this.$router.push(`/move-connections/${this.ix.parentPortUid}?connection=${this.ix.productUid}`)
      this.trackButtonClick('move')
    },
    trackButtonClick(buttonName) {
      captureEvent(`services.${productTypeToEvent(this.port.productType)}.ix.${buttonName}.click`)
    },
  },
}
</script>
