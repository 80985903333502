<template>
  <el-card>
    <div class="card-content"
      role="presentation">
      <div>
        <h3>{{ $t('general.welcome') }}</h3>
        <p>{{ $t('no-services.message') }}</p>
        <h4 class="mt-1">
          {{ $t('no-services.action') }}
        </h4>
        <p>{{ $t('no-services.instructions') }}</p>
      </div>
      <div class="flex-row-centered p-2">
        <el-button type="primary"
          :disabled="filteredLocations.length === 0"
          @click="startTour">
          {{ $t('tour.start-tour') }}
        </el-button>
      </div>
    </div>
  </el-card>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { captureEvent } from '@/utils/analyticUtils'

export default Vue.extend({
  name: 'NoServices',

  computed: {
    ...mapGetters('Services', ['filteredLocations']),
  },

  methods: {
    startTour() {
      this.$root.$emit('introStartedAtBeginning', false)
      if (!document.getElementById('intro-step-0') && document.getElementById('hamburgerTriggerButton')) {
        this.$nextTick(() => {
          document.getElementById('hamburgerTriggerButton').click()
          this.$tours['introTour'].start()
        })
      } else {
        this.$tours['introTour'].start()
      }
      captureEvent('services.start-tour.click')
    },
  },
})
</script>

<style lang="scss" scoped>
.card-content {
  display: flex;
  justify-content: space-between;
}
</style>
