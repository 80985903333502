<template>
  <el-dialog v-if="visible"
    :visible="visible"
    :before-close="handleClose"
    :title="$t('ports.service-key-name', { name: service.productName })"
    width="85%"
    @opened="loadServiceKeys">
    <!-- Service Keys Table -->
    <el-table v-loading="isLoading"
      :data="serviceKeys"
      :default-sort="{prop: 'description', order: 'ascending'}"
      stripe
      max-height="400"
      class="normal-wrap mb-1-4">
      <!-- Service Keys Table Column: Label -->
      <el-table-column :label="$t('general.label')"
        min-width="180"
        prop="description"
        sortable
        :sort-by="sortByLabel">
        <template #default="{row}">
          <span>
            {{ row.description }}
          </span>
        </template>
      </el-table-column>

      <!-- Service Keys Table Column: Valid Until -->
      <el-table-column :label="$t('ports.key-valid-until')"
        prop="validFor.end"
        min-width="130"
        sortable
        :sort-by="sortByDate">
        <template #default="{row}">
          <span v-if="row.validFor">
            {{ convertDate(row.validFor.end) || '-' }}
          </span>
          <!-- Expired key tag -->
          <el-tooltip v-if="row.expired"
            :content="$t('general.expired')"
            placement="top-end">
            <span class="expired-key">
              <span class="sr-only">
                {{ $t('general.expired') }}
              </span>
              <i class="far fa-clock"
                aria-hidden="true" />
            </span>
          </el-tooltip>
        </template>
      </el-table-column>

      <!-- Service Keys Table Column: Key -->
      <el-table-column :label="$t('api-keys.key')"
        prop="key"
        min-width="360">
        <template #default="{row}">
          <div class="flex-row-centered justify-content-space-between gap-0-5 white-space-nowrap">
            {{ row.key }}
            <el-tooltip :content="$t('general.copy-thing-to-clipboard', { thing: $t('services.service-key') })"
              placement="top-end">
              <!-- Copy to clipboard button -->
              <el-button size="mini"
                @click="copyToClipboard(row.key)">
                <span class="sr-only">
                  {{ $t('general.copy-thing-to-clipboard', { thing: $t('services.service-key') }) }}
                </span>
                <i class="far fa-clipboard"
                  aria-hidden="true" />
              </el-button>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>

      <!-- Service Keys Table Column: Type -->
      <el-table-column :label="$t('general.type')"
        min-width="100">
        <template #default="{row}">
          <span>
            {{ row.singleUse ? $t('general.single-use') : $t('general.multi-use') }}
          </span>
        </template>
      </el-table-column>

      <!-- Service Keys Table Column: Rate Limit -->
      <el-table-column :label="$t('services.rate-limit')"
        min-width="120">
        <template #default="{row}">
          <span>
            {{ row.maxSpeed + ' ' + $t('general.mbps') }}
          </span>
        </template>
      </el-table-column>

      <!-- Service Keys Table Column: VLAN -->
      <el-table-column v-if="!isMcr"
        :label="$t('connections.vlan')"
        min-width="80">
        <template #default="{row}">
          <span>
            {{ row.singleUse && row.vlan ? row.vlan : '-' }}
          </span>
        </template>
      </el-table-column>

      <!-- Service Keys Table Column: vNic -->
      <el-table-column v-if="isMve"
        :label="$t('connections.end-vnic', { end: 'B' })"
        min-width="100">
        <template #default="{row}">
          <span>
            <!--
              Stupid jest will chuck a syntax error hissy fit if
              we dare try to pass in the index directly as a param 😡
            -->
            {{ vnicDescription(row) }}
          </span>
        </template>
      </el-table-column>

      <!-- Service Keys Table Column: Enabled -->
      <el-table-column :label="$t('connections.enabled')"
        min-width="80"
        align="center"
        label-class-name="capitalize-text">
        <template #default="{row}">
          <el-checkbox v-model="row.active"
            @change="handleServiceKeyActiveClick(row)" />
        </template>
      </el-table-column>

      <!-- Service Keys Table Column: Action Buttons -->
      <el-table-column :label="$t('general.actions')"
        min-width="80"
        align="center"
        fixed="right">
        <!-- Edit -->
        <template #default="{row, $index}">
          <el-button size="mini"
            :data-name="`keyEdit${$index}`"
            :data-testid="`keyEdit-${$index}`"
            @click="handleServiceKeyEdit($index, row)">
            {{ $t('general.edit') }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- Add/Update Service Key Form -->
    <el-dialog :visible.sync="showAddUpdateModal"
      :title="serviceKeyLabel"
      :close-on-click-modal="false"
      append-to-body>
      <el-form ref="serviceKeyForm"
        :model="serviceKeyForm"
        :rules="serviceKeyRules"
        label-width="185px">
        <!-- Service Key Field: Label -->
        <el-form-item prop="description"
          :label="$t('general.label')">
          <el-input v-model="serviceKeyForm.description"
            data-demo="Service Key Demo"
            data-name="serviceKeyLabel"
            :data-testid="`serviceKeyForm-label-${service._subUid}`" />
        </el-form-item>

        <!-- Service Key Field: Type -->
        <el-form-item prop="singleUse"
          :label="$t('general.type')">
          <el-radio-group v-model="serviceKeyForm.singleUse">
            <el-radio-button :label="true"
              data-name="singleUse"
              :data-testid="`serviceKeyForm-singleUse-${service._subUid}`">
              {{ $t('general.single-use') }}
            </el-radio-button>
            <el-radio-button :label="false"
              data-name="multiUse"
              :data-testid="`serviceKeyForm-multiUse-${service._subUid}`">
              {{ $t('general.multi-use') }}
            </el-radio-button>
          </el-radio-group>
        </el-form-item>

        <!-- Service Key Field: Rate Limit -->
        <el-form-item prop="maxSpeed">
          <template #label>
            {{ $t('services.rate-limit') }} ({{ $t('general.mbps') }})
            <el-tooltip placement="top"
              :content="$t('ports.max-speed-tooltip', { speed: maxRateLimit, productType: serviceType })"
              :open-delay="500">
              <i class="fas fa-question-circle color-info popover-info-icon"
                aria-hidden="true" />
            </el-tooltip>
          </template>
          <el-input v-model.number="serviceKeyForm.maxSpeed"
            name="maxSpeed"
            :data-demo="maxRateLimit"
            :data-testid="`serviceKeyForm-maxSpeed-${service._subUid}`" />
        </el-form-item>

        <!-- Service Key Field: B-End vNIC -->
        <el-form-item v-if="isMve && service.vnics.length > 1"
          prop="vNicIndex">
          <template #label>
            {{ $t('connections.end-vnic', { end: 'B' }) }}
            <el-tooltip placement="top"
              :content="$t('connections.b-end-vnic-help')"
              :open-delay="500">
              <i class="fas fa-question-circle color-info popover-info-icon"
                aria-hidden="true" />
            </el-tooltip>
          </template>
          <el-select v-model="serviceKeyForm.bEnd.vNicIndex"
            data-testid="vnic-select"
            class="full-width"
            @change="vNicChangeHandler">
            <el-option v-for="(vnic, index) in service.vnics"
              :key="`vNIC-${index}`"
              :label="`vNIC-${index} ${vnic.description || ''}`"
              :value="index" />
          </el-select>
        </el-form-item>

        <!-- Service Key Field: VLAN -->
        <el-collapse-transition>
          <el-form-item v-if="serviceKeyForm.singleUse && !isMcr"
            prop="vlan"
            class="vlan-input">
            <template #label>
              {{ serviceKeyVlanLabel }}
              <el-tooltip placement="top"
                :content="$t('connections.service-key-vlan-range')"
                :open-delay="500">
                <i class="fas fa-question-circle color-info popover-info-icon"
                  aria-hidden="true" />
              </el-tooltip>
            </template>
            <el-input v-model.number="serviceKeyForm.vlan"
              name="vlan"
              type="number"
              :min="minVlan"
              :max="maxVlan"
              data-demo="90"
              :data-testid="`serviceKeyForm-vlan-${service._subUid}`"
              @input="debounceVlan" />
            <p class="vlan-message-style"
              :class="serviceKeyVlanValidation.class"
              v-html="serviceKeyVlanValidation.message" /> <!-- eslint-disable-line vue/no-v-html -->
          </el-form-item>
        </el-collapse-transition>

        <!-- Service Key Field: MCR Configuration -->
        <el-collapse-transition>
          <el-form-item v-if="isMcr && serviceKeyForm.singleUse"
            prop="mcrConfig"
            :label="$t('general.type-configuration', { product: $t('productNames.mcr') })">
            <el-button type="primary"
              plain
              @click="showMcrConfigModal = true">
              {{ $t('general.type-configuration', { product: $t('general.x-end', { end: 'B' }) }) }}
            </el-button>
            <i v-if="isMcrConfigEdited"
              class="fas fa-check ml-1"
              aria-hidden="true" />
          </el-form-item>
        </el-collapse-transition>

        <!-- Service keys MCR Config Dialog -->
        <el-dialog v-if="showMcrConfigModal"
          :visible.sync="showMcrConfigModal"
          :title="$t('connections.mcr-config', { end: 'B' })"
          :close-on-click-modal="false"
          width="75%"
          append-to-body>
          <mcr-config key="bEnd"
            end="B"
            :config="serviceKeyForm.bEnd"
            :mcr-port="service"
            service-key
            hide-title
            @update="updateMcrConfig($event)" />
          <template #footer>
            <el-button @click="showMcrConfigModal = false">
              {{ $t('general.close') }}
            </el-button>
            <el-button type="primary"
              @click="saveMcrConfig">
              {{ $t('general.save') }}
            </el-button>
          </template>
        </el-dialog>

        <!-- Service Key Field: Valid Until -->
        <el-form-item prop="validTil"
          :label="$t('ports.key-valid-until')">
          <el-date-picker v-model="serviceKeyForm.validTil"
            type="date"
            :placeholder="$t('general.select-date')"
            :picker-options="datePickerOptions"
            format="MMMM d, yyyy"
            :data-testid="`serviceKeyForm-dataPicker-${service._subUid}`" />
        </el-form-item>

        <!-- Service Key Field: Active -->
        <el-form-item prop="active"
          :label="$t('general.active')">
          <el-checkbox v-model="serviceKeyForm.active"
            :data-testid="`serviceKeyForm-active-${service._subUid}`" />
        </el-form-item>
      </el-form>

      <template #footer>
        <!-- Add Service Key Form Buttons -->
        <div class="text-align-right">
          <!-- Cancel -->
          <el-button :data-testid="`serviceKeyForm-cancel-${service._subUid}`"
            @click="showAddUpdateModal = false">
            {{ $t('general.cancel') }}
          </el-button>
          <!-- Save/Add -->
          <el-button :disabled="isLoading"
            data-name="saveServiceKey"
            :data-testid="`serviceKeyForm-save-${service._subUid}`"
            type="primary"
            @click="saveServiceKey">
            {{ serviceKeyLabel }}
          </el-button>
        </div>
      </template>
    </el-dialog>

    <template #footer>
      <!-- Service Keys Table Buttons -->
      <div class="text-align-right">
        <!-- Close -->
        <el-button data-name="serviceKeyClose"
          :data-testid="`serviceKeyCloseAdd-${service._subUid}`"
          @click="setVisible(false)">
          {{ $t('general.close') }}
        </el-button>
        <!-- Create Service Key -->
        <el-button type="primary"
          data-name="serviceKeyOpenAdd"
          :data-testid="`serviceKeyOpenAdd-${service._subUid}`"
          @click="handleServiceKeyAdd">
          {{ $t('general.create-thing', { thing: $t('services.service-key') }) }}
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
// External tools
import { mapMutations } from 'vuex'
import { DateTime } from 'luxon'
import { debounce } from 'lodash'
import sdk, { CanceledError } from '@megaport/api-sdk'
// Internal tools
import captureSentryError from '@/utils/CaptureSentryError.js'
import { captureEvent, productTypeToEvent } from '@/utils/analyticUtils'
import { copyToClipboard } from '@/helpers.js'
// Components
import McrConfig from '@/components/connection-extras/McrConfig.vue'

export default {
  name: 'ServiceKeys',

  components: {
    'mcr-config': McrConfig,
  },

  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    service: {
      type: Object,
      required: true,
    },
    serviceType: {
      type: String,
      required: false,
      default: '',
    },
  },

  emits: ['update:visible'],

  data() {
    return {
      showAddUpdateModal: false, // Adding/editing service keys modal
      showMcrConfigModal: false, // MCR configuration modal
      editIndex: -1,
      isLoading: false,
      serviceKeyForm: {
        description: '',
        active: true,
        singleUse: false,
        maxSpeed: null,
        vlan: null,
        validTil: null,
        preApproved: true,
        // For MCRs/MVEs
        bEnd: {},
      },
      serviceKeyRules: {
        description: { required: true, message: this.$t('validations.required', { thing: this.$t('general.label') }), trigger: 'blur' },
        maxSpeed: { validator: this.validateMaxSpeed, trigger: 'blur' },
        vlan: { validator: this.validateVlan, trigger: 'blur' },
      },
      serviceKeyVlanValidation: {
        message: '',
        class: '',
      },
      // A copy of the MCR configuration to allow for manual submission/reverting
      mcrConfigDraft: {
        partnerConfig: {},
      },
      serviceKeys: [],
      minVlan: 2,
      maxVlan: 4093,
      abortController: null,
      datePickerOptions: {
        disabledDate(date) {
          return date < new Date()
        },
      },
    }
  },

  computed: {
    isMve() {
      return this.service.productType === this.G_PRODUCT_TYPE_MVE
    },
    isMcr() {
      return this.service.productType === this.G_PRODUCT_TYPE_MCR2
    },
    /** Returns label for either adding or updating a service key */
    serviceKeyLabel() {
      return this.$t(`general.${this.editIndex === -1 ? 'add' : 'update'}-type`, { type: this.$t('services.service-key') })
    },
    /** Returns label for the VLAN field when adding or updating a service key */
    serviceKeyVlanLabel() {
      if (this.isMve || this.isMcr) {
        return this.$t('connections.preferred-end-vlan', { title: this.$t('general.x-end', { end: 'B' }) })
      }
      return this.$t('connections.vlan')
    },
    maxRateLimit() {
      return this.service.maxVxcSpeed || this.service._aggSpeed
    },
    selectedVnicVlan() {
      return this.service.vnics[this.serviceKeyForm.bEnd?.vNicIndex]?.vlan
    },
    /** Just check that something config related has been made */
    isMcrConfigEdited() {
      return this.serviceKeyForm.bEnd?.partnerConfig?.connectType
    },
  },

  created() {
    // Create a debounced version of service key VLAN validation with a delay for the user to finish typing.
    this.debouncedCheckVlanAvailability = debounce(this.validateServiceKeyVlan, 500)
  },

  beforeDestroy() {
    this.debouncedCheckVlanAvailability.cancel()
  },

  methods: {
    ...mapMutations('Notifications', ['notifyGood', 'notifyBad']),
    copyToClipboard,

    /**
     * Set modal visibility and reset modal state
     * @param {boolean} newValue Modal visibility
     */
    setVisible(newValue) {
      this.$emit('update:visible', newValue)
    },
    /**
     * Hide modal when closing
     * @param {Function} done
     */
    handleClose(done) {
      this.setVisible(false)
      done()
    },

    /**
     * Validate the max speed entered by the user
     * @param {Object} _rule
     * @param {string} value
     * @param {Function} callback
     */
    validateMaxSpeed(_rule, value, callback) {
      // Accept empty value
      if (!value) {
        callback()
        return
      }

      // Check if the value is a positive integer
      if (value <= 0) {
        callback(this.$t('ports.vxc-speed-positive-integer'))
        return
      }

      // Check if the value is less than or equal to the max speed
      const maxSpeed = this.service.maxVxcSpeed || this.service.portSpeed
      if (value > maxSpeed) {
        callback(this.$t('ports.vxc-speed-max', { speed: maxSpeed }))
        return
      }

      callback()
    },

    /**
     * Validate the VLAN entered by the user
     * @param {Object} _rule
     * @param {string} value
     * @param {Function} callback
     */
    async validateVlan(_rule, value, callback) {
      // Only validate if the VLAN has changed
      const originalVlan = this.serviceKeys.find(key => key.key === this.serviceKeyForm.key)?.vlan

      if (!value || this.serviceKeyVlanValidation.message === this.$t('validations.vlan-available')
        || value === originalVlan) {
        callback()
        return
      }

      if (!this.serviceKeyVlanValidation.message) {
        if (this.isMve) {
          await this.validateServiceKeyVlan(this.selectedVnicVlan, value)
        } else {
          await this.validateServiceKeyVlan(value)
        }
      }

      callback('false')
    },

    /**
     * Validates the VLAN entered by the user for a single use service key.
     * This is a stripped down VLAN validation that simply checks the API for the VLAN's availability.
     * @param {number} vlan The VLAN to check against
     */
    async validateServiceKeyVlan(vlan, innerVlan = null) {
      // Don't go any further if the VLAN is invalid
      if (vlan < this.minVlan || vlan > this.maxVlan || (innerVlan && (innerVlan < this.minVlan || innerVlan > this.maxVlan))) {
        this.serviceKeyVlanValidation = {
          message: this.$t(`validations.vlan-range`, { minVlan: this.minVlan, maxVlan: this.maxVlan }),
          class: 'color-danger',
        }
        return
      }

      this.serviceKeyVlanValidation = {
        message: this.$t('connections.vlan-checking'),
        class: 'color-warning',
      }

      const vlansSet = new Set()

      try {
        let signal = null
        this.abortController = new AbortController()
        signal = this.abortController.signal

        const vlans = await sdk.instance
          .product(this.service.productUid)
          .checkVlan(vlan, null, innerVlan, null, { signal })

        if (vlans.vlan) {
          vlansSet.add(vlans.vlan)
          vlans.suggested_vlans.forEach(item => vlansSet.add(item))
        } else {
          vlans.forEach(item => vlansSet.add(item))
        }
      } catch (error) {
        // If it was aborted by a new request coming in, don't treat it as an error.
        // The new request will handle the status updates.
        if (error instanceof CanceledError) {
          // Something unexpected went wrong with the fetch
          captureSentryError(error)

          this.serviceKeyVlanValidation = {
            message: this.$t('validations.vlan-unavailable'),
            class: 'color-danger',
          }
        }

        return false
      }

      // The checkVlan method will either return an array with one item in it, which will be the requested
      // VLAN, indicating that it's OK, or it will be a list of suggested VLANs.
      if (vlansSet.size > 1) {
        this.serviceKeyVlanValidation = {
          message: this.$t(`VLAN unavailable.<br/>${[...vlansSet].join(', ')} are known to be available for use on this service.`),
          class: 'color-warning',
        }
      } else {
        this.serviceKeyVlanValidation = {
          message: this.$t('validations.vlan-available'),
          class: 'color-success',
        }
      }
    },

    /**
     * Load service keys for the current service
     */
    loadServiceKeys() {
      // Capture the event for viewing service keys for GTM/Posthog
      captureEvent(`services.${productTypeToEvent(this.service.productType)}.view-keys.click`)

      this.isLoading = true
      sdk.instance
        .product(this.service.productUid)
        .getKey()
        .then(keys => {
          keys.sort((a, b) => {
            if (a.createDate < b.createDate) return -1
            if (a.createDate > b.createDate) return 1
            return 0
          })

          // BE returns an "empty" VLAN as 0 so we need to convert it to null for the UI
          for (const key of keys) {
            if (key.vlan === 0) key.vlan = null
          }

          this.serviceKeys = keys
          this.isLoading = false
        })
        .catch(e => {
          captureSentryError(e)
        })
    },

    /**
     * Setup add service key form and open modal
     */
    handleServiceKeyAdd() {
      this.editIndex = -1

      const expiry = new Date()
      expiry.setDate(expiry.getDate() + 30)

      this.serviceKeyForm = {
        description: '',
        active: true,
        singleUse: false,
        maxSpeed: null,
        vlan: null,
        validTil: expiry,
        preApproved: true,
        bEnd: {
          // MVEs
          ...(this.isMve ? { vNicIndex: 0 } : {}),
          // MCRs
          ...(this.isMcr ? { partnerConfig: {} } : {}),
        },
      }

      this.showAddUpdateModal = true
      this.serviceKeyVlanValidation = { message: '', class: '' }
      this.$nextTick(() => {
        this.$refs.serviceKeyForm.clearValidate()
      })
    },

    /**
     * Setup edit service key form and open modal
     * @param {number} index Table row index
     * @param {Object} row Service key row data
     */
    handleServiceKeyEdit(index, row) {
      this.editIndex = index

      Object.assign(this.serviceKeyForm, row)
      if (row.validFor) delete this.serviceKeyForm.validFor
      this.serviceKeyForm.validTil = row.validFor?.end ? new Date(row.validFor.end) : null

      this.showAddUpdateModal = true
      this.serviceKeyVlanValidation = { message: '', class: '' }
      this.$nextTick(() => {
        this.$refs.serviceKeyForm.clearValidate()
      })
    },

    /**
     * Toggle active status for an existing service key
     * @param {Object} row Service key row data
     */
    handleServiceKeyActiveClick(row) {
      this.isLoading = true

      sdk.instance
        .product(this.service.productUid)
        .updateKey(row)
        .then(({ description }) => {
          this.notifyGood({
            title: this.$t('ports.key-saved'),
            message: this.$t('ports.key-saved-message', { description }),
          })
          this.loadServiceKeys()
        })
        .catch(d => {
          // TODO: Improve error processing
          this.notifyBad({
            title: this.$t('ports.key-update-error'),
            message: d.data?.message,
          })
          this.isLoading = false
        })
    },

    /**
     * Adds/updates a service key via the API
     */
    saveServiceKey() {
      this.$refs.serviceKeyForm.validate(valid => {
        if (!valid) {
          const props = {
            title: this.$t('validations.failed'),
            message: this.$t('validations.correct-issues'),
            type: 'error',
            duration: 3000,
          }
          this.$notify(props)

          return
        }
        this.isLoading = true

        const saveKeyObj = { ...this.serviceKeyForm }

        // Reformat expiry date into start/end for API
        saveKeyObj.validFor = {
          start: Date.now(),
          end: saveKeyObj.validTil,
        }
        delete saveKeyObj.validTil

        // Set VLAN to 0 if it's empty for MVEs (for both single use and multi-use)
        if (this.isMve && (!saveKeyObj.vlan || !this.serviceKeyForm.singleUse)) {
          saveKeyObj.vlan = 0
        }

        // Remove VLAN if it's not a single use key for non-MVEs or for MCRs
        if ((!saveKeyObj.singleUse && !this.isMve) || this.isMcr) {
          delete saveKeyObj.vlan
        }

        if (this.editIndex === -1) {
          // Create a new service key
          sdk.instance
            .product(this.service.productUid)
            .createKey(saveKeyObj)
            .then(({ description }) => {
              this.notifyGood({
                title: this.$t('ports.key-added'),
                message: this.$t('ports.key-saved-message', { description }),
              })
              this.loadServiceKeys()
              this.showAddUpdateModal = false
            })
            .catch(d => {
              // TODO: Improve error processing
              this.notifyBad({
                title: this.$t('ports.key-add-error'),
                message: d.data?.message,
              })
              this.isLoading = false
            })
        } else {
          // Update an existing service key
          sdk.instance
            .product(this.service.productUid)
            .updateKey(saveKeyObj)
            .then(({ description }) => {
              this.notifyGood({
                title: this.$t('ports.key-saved'),
                message: this.$t('ports.key-saved-message', { description }),
              })
              this.loadServiceKeys()
              this.showAddUpdateModal = false
            })
            .catch(d => {
              // TODO: Improve error processing
              this.notifyBad({
                title: this.$t('ports.key-update-error'),
                message: d.data?.message,
              })
              this.isLoading = false
            })
        }
      })
    },

    /**
     * Update MCR draft config with configuration details
     * @param configuration MCR configuration object
     */
    updateMcrConfig(configuration) {
      this.mcrConfigDraft = configuration
    },

    /**
     * Save MCR configuration to form for submission and close modal
     */
    saveMcrConfig() {
      this.serviceKeyForm.bEnd = this.mcrConfigDraft
      this.showMcrConfigModal = false
    },

    sortByLabel(row) {
      return row.description.toLocaleLowerCase()
    },

    convertDate(dateNumber) {
      return dateNumber ? DateTime.fromMillis(dateNumber).toFormat('yyyy-LL-dd') : null
    },

    sortByDate(row) {
      const currentDate = new Date()
      // Add 100 years to the current date
      currentDate.setFullYear(currentDate.getFullYear() + 100)
      const farAwayDate = DateTime.fromMillis(currentDate.getTime()).toFormat('yyyy-LL-dd')

      return this.convertDate(row.validFor.end) ?? farAwayDate
    },

    /** Get the vNic description to display in the vNic table column */
    vnicDescription(row) {
      return this.service.vnics[row.bEnd?.vNicIndex]?.description ?? '-'
    },

    debounceVlan(value) {
      if (this.isMve) {
        this.debouncedCheckVlanAvailability(this.selectedVnicVlan, value)
      } else {
        this.debouncedCheckVlanAvailability(value)
      }
    },

    /** Call VLAN validation when changing the vNic index */
    vNicChangeHandler() {
      if (this.serviceKeyForm.singleUse && this.serviceKeyForm.vlan) {
        this.debouncedCheckVlanAvailability(this.selectedVnicVlan, this.serviceKeyForm.vlan)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  max-width: 1500px;

  .el-dialog__body {
    padding-block: 1rem;
  }

  @media (max-width: 1200px) {
    width: 96% !important;
  }
}

.vlan-message-style {
  line-height: 2.5rem;
  margin: 0;
}

::v-deep .vlan-input .el-form-item__error {
  display: none;
}

.expired-key {
  padding-inline: 0.5rem;
  padding-block: 0.25rem;
  color: var(--color-danger);
  font-size: 1rem;
  border: 1px solid var(--color-danger-light-5);
  border-radius: var(--border-radius-base);
  background-color: var(--color-danger-light-8);
}

.fa-check {
  color: var(--color-success);
  font-size: 20px;
}
</style>
